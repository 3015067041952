<script setup>
import { defineProps, defineEmits, watch } from 'vue';

const props = defineProps({
	modelValue: Boolean,
	transition: { type: Boolean, default: true },
	modalClass: { type: String, default: '' }
});

const emit = defineEmits(['update:modelValue']);

function closeModal() {
	emit('update:modelValue', false);
}

watch(
	() => props.modelValue,
	(newVal) => {
		if (!newVal) closeModal();
	}
);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>

<template>
	
	<transition name="fade" v-if="transition" @click.stop="closeModal">
		<div v-if="modelValue" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
			<div class="bg-white rounded-lg py-4 px-4  w-[90%] text-gray-700   max-w-lg mx-auto" :class="modalClass" @click.stop>
				<slot></slot>
			</div>
		</div>
	</transition>
	<div v-else-if="modelValue" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]" @click.stop="closeModal">
		<div class="bg-white rounded-lg py-4 px-4 w-[90%]   text-gray-700  max-w-lg mx-auto" :class="modalClass" @click.stop>
			<slot></slot>
		</div>
	</div>
</template>